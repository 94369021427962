import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Card, Button, Col, Row, Form, FloatingLabel, Dropdown} from 'react-bootstrap';
import {Checkbox, FormControlLabel, Tab, Tabs, Typography} from '@material-ui/core';
import {green} from '@material-ui/core/colors';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {useIdleTimer} from 'react-idle-timer';
import * as Yup from 'yup';
import {ModalPaymentMethod} from '../Modal/ModalPaymentMethod';
import {ModalTax} from '../Modal/ModalTax';
import {ModalError} from '../Modal/ModalError';
import {ModalAnnualReport} from '../Modal/ModalAnnualReport';
import {useForm} from 'react-hook-form';
import {apiPostalCode} from '../../../../../dataSource/postalCodeRepo';
import {apiMemberLions} from '../../../../../dataSource/accountRepo';
import {apiGetProgramme, apiSendDonation} from '../../../../../dataSource/donationRepo';
import {yupResolver} from '@hookform/resolvers/yup';
import nric from 'nric';
import './index.css';

const AntTabs = withStyles({
  root: {
    border: 'none',
    width: window.innerWidth < 900 ? '300px' : '100%',
    marginLeft: window.innerWidth < 900 ? '80px' : '40px',
  },
  indicator: {
    backgroundColor: '#2879d0',
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      maxWidth: 20,
      width: '0%',
    },
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: window.innerWidth < 900 ? 0 : 350,
    fontSize: window.innerWidth < 900 ? '11px' : '30px',
    marginRight: theme.spacing(1),
    borderBottom: '1px solid #e8e8e8',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '700',
    color: '#999',
    outline: 'none',
    border: 'none',
    '&:hover': {
      opacity: 1,
      border: 'none',
    },
    '&$selected': {
      color: '#333333DE',
      border: 'none',
    },
    '&:focus': {
      color: '#333333DE',
      border: 'none',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    fontSize: 25,
  },
  checked: {},
})(props => (
  <Checkbox
    color="default"
    disableRipple
    style={{borderRadius: '50px'}}
    icon={<CircleUnchecked />}
    checkedIcon={<CircleCheckedFilled />}
    {...props}
  />
));

export function DonationForm() {
  const classes = useStyles();
  const history = useHistory();
  const frequencyRef = useRef();
  const amountRef = useRef(null);
  const [loadingDonation, setLoadingDonation] = useState(false);
  const [donationNumber, setDonationNumber] = useState(null);
  const [conditionAnnualReport, setConditionAnnualReport] = useState(true);

  const validationSchema = Yup.object().shape({
    donationType: Yup.string(),
    amount: Yup.string()
      .required('Please input the amount')
      .test('amount', 'Minimum amount is $10', function () {
        if (amountRef.current.value >= 10) {
          return true;
        } else {
          return new Yup.ValidationError('Minimum amount is $10', null, 'amount');
        }
      }),
    fullname: Yup.string().when('donationType', {
      is: val => val !== 'Anonymous',
      then: Yup.string()
        .required('Field is required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    }),
    orgName: Yup.string().when('donationType', {
      is: 'Organisation',
      then: Yup.string().required('Field is required'),
    }),
    email: Yup.string().when('donationType', {
      is: val => val !== 'Anonymous',
      then: Yup.string()
        .email('Please provide a valid email address')
        .required('Field is required'),
      otherwise: Yup.string().email('Please provide a valid email address').notRequired(),
    }),
    salutation: Yup.string().when('donationType', {
      is: val => val !== 'Anonymous',
      then: Yup.string().required('Field is required'),
    }),
    preferredName: Yup.string().when('donationType', {
      is: val => val !== 'Anonymous',
      then: Yup.string().required('Field is required'),
    }),
    otherSalutation: Yup.string().when('salutation', {
      is: 'Others',
      then: Yup.string().required('Field is required'),
    }),
    phone: Yup.string().matches(/^[689]\d{7}$/, {
      message: 'Please provide a valid phone number',
      excludeEmptyString: true,
    }),
    isTax: Yup.boolean(),
    idType: Yup.string().when(['isTax', 'donationType'], {
      is: (istax, donType) => istax === true && donType !== 'Anonymous',
      then: Yup.string().required('Please select ID type'),
    }),
    taxFullname: Yup.string().when(['isTax', 'donationType'], {
      is: (istax, donType) => istax === true && donType !== 'Anonymous',
      then: Yup.string().required('Field is required'),
    }),
    idNumber: Yup.string().when(['isTax', 'donationType'], {
      is: (istax, donType) => istax === true && donType !== 'Anonymous',
      then: Yup.string().when('idType', {
        is: idType => idType === 'nric',
        then: Yup.string()
          .required('Please provide ID Number')
          .test('idNumber', null, (str, ctx) => {
            if (str.length !== 9) {
              return new Yup.ValidationError('Invalid number', null, 'idNumber');
            } else {
              str = str?.toUpperCase();
              const condition = nric.validate(str);
              if (condition) {
                return true;
              } else {
                return new Yup.ValidationError('Invalid number', null, 'idNumber');
              }
            }
          }),
        otherwise: Yup.string()
          .required('Please provide ID Number')
          .test('idNumber', null, uen => {
            var debug = true;
            const entityTypeIndicator = [
              'LP',
              'LL',
              'FC',
              'PF',
              'RF',
              'MQ',
              'MM',
              'NB',
              'CC',
              'CS',
              'MB',
              'FM',
              'GS',
              'GA',
              'GB',
              'DP',
              'CP',
              'NR',
              'CM',
              'CD',
              'MD',
              'HS',
              'VH',
              'CH',
              'MH',
              'CL',
              'XL',
              'CX',
              'RP',
              'TU',
              'TC',
              'FB',
              'FN',
              'PA',
              'PB',
              'SS',
              'MC',
              'SM',
            ];

            // if (debug) {
            //     console.log('(A) Businesses registered with ACRA');
            //     console.log('(B) Local companies registered with ACRA');
            //     console.log('(C) All other entities which will be issued new UEN');
            // }

            // check that uen is not empty
            if (!uen || String(uen) === '') {
              // if (debug) { console.log('UEN is empty'); }
              return new Yup.ValidationError('Invalid number', null, 'idNumber');
            }

            // check if uen is 9 or 10 digits
            if (uen.length < 9 || uen.length > 10) {
              // if (debug) { console.log('UEN is not 9 or 10 digits'); }
              return new Yup.ValidationError('Invalid number', null, 'idNumber');
            }

            uen = uen.toUpperCase();
            var uenStrArray = uen.split('');

            // (A) Businesses registered with ACRA
            if (uenStrArray.length === 9) {
              // check that last character is a letter
              if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
                // if (debug) { console.log('(A) last character is not an alphabet'); }
                return new Yup.ValidationError('Invalid number', null, 'idNumber');
              }

              for (var i = 0; i < uenStrArray.length - 1; i++) {
                // check that first 8 letters are all numbers
                if (isNaN(uenStrArray[i])) {
                  // if (debug) { console.log('(A) there are non-numbers in 1st to 8th letters'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }
              }

              // (A) Businesses registered with ACRA (SUCCESS)
              // if (debug) { console.log('valid (A) Businesses registered with ACRA'); }
              return true;
            } else if (uenStrArray.length === 10) {
              // check that last character is a letter
              if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
                // if (debug) { console.log('(B)(C) last character is not an alphabet'); }
                return new Yup.ValidationError('Invalid number', null, 'idNumber');
              }

              // (B) Local companies registered with ACRA
              if (
                !isNaN(uenStrArray[0]) &&
                !isNaN(uenStrArray[1]) &&
                !isNaN(uenStrArray[2]) &&
                !isNaN(uenStrArray[3])
              ) {
                // check that 5th to 9th letters are all numbers
                if (
                  !isNaN(uenStrArray[4]) &&
                  !isNaN(uenStrArray[5]) &&
                  !isNaN(uenStrArray[6]) &&
                  !isNaN(uenStrArray[7]) &&
                  !isNaN(uenStrArray[8])
                ) {
                  // (B) Local companies registered with ACRA (SUCCESS)
                  // if (debug) { console.log('valid (B) Local companies registered with ACRA'); }
                  return true;
                } else {
                  // if (debug) { console.log('(B) there are non-numbers in 5th to 9th letters'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }
              }
              // (C) All other entities which will be issued new UEN
              else {
                // check that 1st letter is either T or S or R
                if (uenStrArray[0] !== 'T' && uenStrArray[0] !== 'S' && uenStrArray[0] !== 'R') {
                  // if (debug) { console.log('(C) 1st letter is incorrect'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }

                // check that 2nd and 3rd letters are numbers only
                if (isNaN(uenStrArray[1]) || isNaN(uenStrArray[2])) {
                  // if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }

                // check that 4th letter is an alphabet
                if (!isNaN(uenStrArray[3])) {
                  // if (debug) { console.log('(C) 4th letter is not an alphabet'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }

                // check entity-type indicator
                var entityTypeMatch = false,
                  entityType = String(uenStrArray[3]) + String(uenStrArray[4]);
                for (var i = 0; i < entityTypeIndicator.length; i++) {
                  if (String(entityTypeIndicator[i]) === String(entityType)) {
                    entityTypeMatch = true;
                  }
                }
                if (!entityTypeMatch) {
                  // if (debug) { console.log('(C) entity-type indicator is invalid'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }

                // check that 6th to 9th letters are numbers only
                if (
                  isNaN(uenStrArray[5]) ||
                  isNaN(uenStrArray[6]) ||
                  isNaN(uenStrArray[7]) ||
                  isNaN(uenStrArray[8])
                ) {
                  // if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                  return new Yup.ValidationError('Invalid number', null, 'idNumber');
                }

                // (C) All other entities which will be issued new UEN (SUCCESS)
                // if (debug) { console.log('valid (C) All other entities which will be issued new UEN'); }
                return true;
              }
            }
          }),
      }),
    }),
    paymentMethod: Yup.string(),
    bankName: Yup.string().when('paymentMethod', {
      is: val => val === 'Cheque',
      then: Yup.string().required('Please provide Bank Name'),
    }),
    chequeNo: Yup.string().when('paymentMethod', {
      is: 'Cheque',
      then: Yup.string().required('Please provide Cheque No.'),
    }),
    isPrivacy: Yup.bool().oneOf([true], 'Please agree before continue.'),
    isMember: Yup.string().required('Please pick option'),
    lionsMember: Yup.string().when('isMember', {
      is: 'yes',
      then: Yup.string().required('Please pick the clubs name'),
    }),
  });

  const [dataProgramme, setDataProgramme] = useState(null);
  const [modalPaymentMethod, setModalPaymentMethod] = useState({
    show: false,
    type: null,
    onConfirm: () => null,
  });
  const [modalTax, setModalTax] = useState({
    show: false,
    onConfirm: () => null,
    onCancel: () => null,
    taxFullname: null,
    taxIdNumber: null,
  });
  const [modalAnnualReport, setModalAnnualReport] = useState({
    show: false,
    onConfirm: () => null,
    onCancel: () => null,
  });
  const [modalError, setModalError] = useState({
    show: false,
    error: null,
    message: null,
  });
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    trigger,
    setFocus,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      programmeId: '',
      frequency: 0,
      amount: '',
      donationType: 'Individual',
      orgName: '',
      fullname: '',
      // surname: '',
      preferredName: '',
      taxFullname: '',
      email: '',
      salutation: '',
      otherSalutation: '',
      phone: '',
      isTax: true,
      idType: 'nric',
      idNumber: '',
      postalCode: '',
      address: '',
      unitNumber: '',
      remarks: '',
      includeAnnualReport: false,
      isPrivacy: false,
      isSubscibeUpdate: true,
      isMember: 'no',
      lionsMember: '',
      memberId: '',
      paymentMethod: 'Credit Card',
      bankName: '',
      chequeNo: '',
    },
  });

  const handleChangeFrequency = (event, newValue) => {
    setValue('frequency', newValue);
    amountRef.current.focus();
  };

  const handleCheckbox = event => {
    setValue('isTax', event.target.checked);
    if (watch('donationType') === 'Individual') {
      setValue('idType', 'nric');
    } else if (watch('donationType') === 'Organisation') {
      setValue('idType', 'uen');
    }
  };

  const dataForm = {
    frequency: watch('frequency'),
    amount: watch('amount'),
    donationType: watch('donationType'),
    orgName: watch('orgName'),
    fullname: watch('fullname'),
    // surname: watch('surname'),
    preferredName: watch('preferredName'),
    taxFullname: watch('taxFullname'),
    email: watch('email'),
    phone: watch('phone'),
    isTax: watch('isTax'),
    idType: watch('idType'),
    idNumber: watch('idNumber'),
    postalCode: watch('postalCode'),
    address: watch('address'),
    unitNumber: watch('unitNumber'),
    remarks: watch('remarks'),
    includeAnnualReport: watch('includeAnnualReport'),
    isPrivacy: watch('isPrivacy'),
    isSubscibeUpdate: watch('isSubscibeUpdate'),
    isMember: watch('isMember'),
    lionsMember: watch('lionsMember'),
    paymentMethod: watch('paymentMethod'),
    bankName: watch('bankName'),
  };

  const monthlyAmount = ['50', '100', '200', '300'];
  const onetimeAmount = ['50', '100', '200', '300'];

  function removeFocusAmountDisplay() {
    amountRef.current.blur();
  }

  function focusAmountDisplay() {
    removeFocusAmountDisplay();
    amountRef.current.focus();
  }

  const BoxAmount = ({amount}) => {
    return (
      <div
        onMouseLeave={() => removeFocusAmountDisplay()}
        className="amount"
        style={{
          boxShadow: watch('amount') === amount && '0 2px 10px 0 rgb(0 0 0 / 25%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
          backgroundColor: watch('amount') === amount ? '#2879d0' : '#FFFFFF',
        }}
        onClick={() => {
          focusAmountDisplay();
          setValue('amount', amount);
          trigger('amount');
        }}>
        <span
          style={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
            color: watch('amount') === amount ? '#FFFFFF' : '#999999',
            fontWeight: 'bold',
            marginBottom: watch('frequency') === 1 && 10,
          }}>
          SGD
        </span>
        <span
          style={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '32px',
            color: watch('amount') === amount ? '#FFFFFF' : '#999999',
            fontWeight: 'bold',
          }}>
          {amount}
        </span>
        {watch('frequency') === 0 && (
          <span
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '16px',
              color: watch('amount') === amount ? '#FFFFFF' : '#999999',
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            / month
          </span>
        )}
      </div>
    );
  };

  const BoxDonationType = ({type}) => {
    return (
      <div
        className="col-lg-3 box-donation-type"
        style={{
          border: '1px solid #ced4da',
          boxShadow: watch('donationType') === type && '0 2px 10px 0 rgb(0 0 0 / 25%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: watch('donationType') === type ? '#2879d0' : '#FFFFFF',
          borderRadius: 10,
          height: 50,
          marginRight: 20,
          cursor: 'pointer',
          marginBottom: 5,
        }}
        onClick={() => {
          setValue('donationType', type);
          setValue('idType', type === 'Individual' ? 'nric' : type === 'Organisation' ? 'uen' : '');
          setValue('idNumber', '');
          setValue('taxFullname', '');
          // setValue('surname', '');
          setValue('preferredName', '');
        }}>
        <span
          style={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '20px',
            color: watch('donationType') === type ? '#FFFFFF' : '#999999',
            fontWeight: 'bold',
          }}>
          {type}
        </span>
      </div>
    );
  };

  const BoxPaymentMethod = ({method}) => {
    return (
      <div
        className="box-payment-method"
        style={{
          border: '1px solid #ced4da',
          boxShadow: watch('paymentMethod') === method && '0 2px 10px 0 rgb(0 0 0 / 25%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: watch('paymentMethod') === method ? '#2879d0' : '#FFFFFF',
          borderRadius: 10,
          height: 130,
          marginRight: 20,
          cursor: 'pointer',
          marginBottom: 15,
        }}
        onClick={() => {
          setValue('paymentMethod', method);
          setModalPaymentMethod({
            ...modalPaymentMethod,
            type: method,
          });
        }}>
        <span
          style={{
            fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
            fontSize: '20px',
            color: watch('paymentMethod') === method ? '#FFFFFF' : '#999999',
            fontWeight: 'bold',
          }}>
          {method}
        </span>
      </div>
    );
  };

  const [optionMember, setOptionMember] = useState([]);
  const [suggestionMember, setSuggestionMember] = useState([]);
  const [showOptionMember, setShowOptionMember] = useState(false);

  useEffect(() => {
    getMemberLions();
    const queryParams = new URLSearchParams(window.location.search);
    const programmeId = queryParams.get('id');
    if (programmeId) {
      // setValue('donationType', donationType);
      // console.log('programmeId', programmeId);
      setValue('programmeId', programmeId);
    }
  }, []);

  useEffect(() => {
    if (watch('programmeId') !== '') {
      apiGetProgramme(watch('programmeId')).then(res => {
        setDataProgramme(res.data);
      });
    }
  }, [watch('programmeId')]);

  useEffect(() => {
    setValue('paymentMethod', 'Credit Card');
  }, [watch('frequency')]);

  useEffect(() => {
    if (watch('postalCode').length === 6) {
      apiPostalCode({postalCode: watch('postalCode')})
        .then(res => {
          let addressRes =
            res?.data?.postalcode?.where?.email[res?.data?.postalcode?.where?.email?.length - 1];
          if (!addressRes.includes(watch('postalCode'))) {
            addressRes = addressRes + ' ' + watch('postalCode');
          }
          setValue('address', addressRes);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [watch('postalCode')]);

  function getMemberLions() {
    apiMemberLions()
      .then(res => {
        let _tmpData = res.data;
        setOptionMember(_tmpData);
        setSuggestionMember(_tmpData);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const sendDonation = async data => {
    let {
      email,
      fullname,
      programmeId,
      preferredName,
      idType,
      idNumber,
      taxFullname,
      phone,
      address,
      postalCode,
      unitNumber,
      remarks,
      isMember,
      frequency,
      amount,
      donationType,
      isTax,
      includeAnnualReport,
      isPrivacy,
      isSubscibeUpdate,
      lionsMember,
      memberId,
      paymentMethod,
      orgName,
      bankName,
      chequeNo,
      salutation,
      otherSalutation,
    } = data;

    let individualValues = {
      email,
      fullname,
      programmeId,
      preferredName,
      idType,
      idNumber,
      taxFullname,
      phone,
      address,
      postalCode,
      unitNumber,
      remarks,
      isMember: isMember === 'yes' ? true : false,
      frequency: frequency === 1 ? 'onetime' : 'monthly',
      amount,
      donationType,
      isTax,
      includeAnnualReport,
      isPrivacy,
      isSubscibeUpdate,
      lionsMember,
      memberId,
      paymentMethod,
      salutation,
      otherSalutation,
    };

    let dataSent = {};

    // additional by donationType
    if (donationType === 'Individual') {
      dataSent = {...individualValues};
    } else if (donationType === 'Organisation') {
      dataSent = {...individualValues, orgName};
    } else {
      dataSent = {
        email,
        frequency: frequency === 1 ? 'onetime' : 'monthly',
        amount,
        donationType,
        includeAnnualReport,
        isPrivacy,
        isSubscibeUpdate,
        paymentMethod,
      };
    }

    // addtional by paymentMethod
    if (paymentMethod === 'Cheque') {
      dataSent = {...dataSent, bankName, chequeNo};
    }

    // send data donation to API
    try {
      let result = await apiSendDonation(dataSent);
      setDonationNumber(result.data.donation.number);
      return result;
    } catch (error) {
      setLoadingDonation(false);
      setModalError({
        show: true,
        error: error,
        message: error.response.data.message ? error.response.data.message : error.message,
      });
      return error;
    }
  };

  async function submitDonation(value) {
    localStorage.setItem('dataForm', JSON.stringify(value));
    // ---------- payment method Creadit Card --------------
    if (value.paymentMethod === 'Credit Card') {
      try {
        setLoadingDonation(true);
        let response = await sendDonation(value);
        if (response.status) setLoadingDonation(false);
        if (response.status === 200) {
          window.location.href = response.data?.paymentUrl;
        }
      } catch (error) {
        setLoadingDonation(false);
        setModalError({
          show: true,
          error: error,
          message: error.message,
        });
      }
    }

    // ---------------- payment method Paynow -------------------
    if (value.paymentMethod === 'PayNow') {
      try {
        setLoadingDonation(true);
        let response = await sendDonation(value);
        if (response.status === 200) {
          setLoadingDonation(false);
          setModalPaymentMethod({
            ...modalPaymentMethod,
            show: true,
            type: value.paymentMethod,
            onConfirm: () => {
              history.push({
                pathname: '/thank-you',
                data: {...response.data, ...dataForm},
              });
            },
          });
        }
      } catch (error) {
        setLoadingDonation(false);
        setModalError({
          show: true,
          error: error,
          message: error.message,
        });
      }
    }

    // ---------------- payment method Cheque -------------------
    if (value.paymentMethod === 'Cheque') {
      try {
        setLoadingDonation(true);
        let response = await sendDonation(value);
        if (response.status === 200) {
          setLoadingDonation(false);
          setModalPaymentMethod({
            ...modalPaymentMethod,
            show: true,
            type: value.paymentMethod,
            onConfirm: () => {
              history.push({
                pathname: '/thank-you',
                data: {...response.data, ...dataForm},
              });
            },
          });
        }
      } catch (error) {
        setLoadingDonation(false);
        setModalError({
          show: true,
          error: error,
          message: error.message,
        });
      }
    }
  }

  useEffect(() => {
    if (errors?.amount) {
      amountRef.current.focus();
    }
  }, [errors]);

  useEffect(() => {
    if (watch('idNumber') !== '') {
      trigger('idNumber');
    }
  }, [watch('idType')]);

  useEffect(() => {
    localStorage.removeItem('dataForm');
    amountRef.current.focus();
  }, []);

  const onChangeMember = text => {
    let matches = [];
    if (text.length > 0) {
      matches = optionMember.filter(arg => {
        const regex = new RegExp(`${text}`, 'gi');
        return arg.Name.match(regex);
      });
      setSuggestionMember(matches);
    } else {
      setSuggestionMember(optionMember);
    }
  };

  const onIdle = () => {
    localStorage.removeItem('dataForm');
    reset();
    window.scrollTo(0, 0);
  };

  const onActive = event => {
    // do something on active
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: 1000 * 60 * 30, // timeout idle on 30 minutes
  });

  const handleBlurAddress = () => {
    if (watch('postalCode').length === 6) {
      if (!watch('address').includes(watch('postalCode'))) {
        let _address = watch('address') + ' ' + watch('postalCode');
        setValue('address', _address);
      }
    }
  };

  return (
    <>
      <section>
        <Container>
          <form onSubmit={handleSubmit(submitDonation)}>
            <Card
              className="card-body"
              style={{
                padding: '40px',
                border: '2px solid rgb(221, 221, 221)',
              }}>
              <h3 style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
                I am donating to {dataProgramme?.Name ?? 'General Donation'}
              </h3>
              <Container
                ref={frequencyRef}
                style={{
                  padding: '0px 0px 30px',
                  marginTop: '30px',
                  display: 'block',
                }}>
                <h5 style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
                  {watch('frequency') === 0
                    ? 'Your contribution sustains a meaningful impact, enriching the lives of our seniors with compassion and care'
                    : 'Your contribution will help to enrich the lives of our seniors with compassion and care.'}
                </h5>
                <div className={`${classes.root} mb-1`}>
                  <div className={classes.demo1}>
                    <AntTabs
                      value={watch('frequency')}
                      onChange={handleChangeFrequency}
                      aria-label="ant example">
                      <AntTab
                        onClick={() => {
                          if (watch('donationType') === 'Anonymous') {
                            setValue('donationType', 'Individual');
                          }
                        }}
                        label="DONATE MONTHLY"
                      />
                      <AntTab label="DONATE ONCE" />
                    </AntTabs>
                    <Typography className={classes.padding} />
                  </div>
                </div>
                <Row>
                  <Col xl={12} xs={12} lg={12}>
                    <div
                      className="col-lg-12 col-xl-12 col-xs-12 col-sm-12"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Col lg={12} xs={12} className="mt-4 d-flex justify-content-center">
                        <Form.Group
                          as={Row}
                          className="col-md-12 col-lg-10 col-xl-10"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <span
                            style={{
                              fontFamily: 'Montserrat, sans-serif',
                              fontSize: '28px',
                              color: '#666666',
                              position: 'absolute',
                              zIndex: 1,
                              left: '15%',
                              width: 'auto',
                            }}>
                            S${' '}
                          </span>
                          <Form.Control
                            ref={amountRef}
                            onMouseEnter={focusAmountDisplay}
                            onMouseLeave={removeFocusAmountDisplay}
                            className="amount-display col-lg-8"
                            style={{
                              height: '70px',
                              borderRadius: 10,
                              border: '1px solid rgb(221, 221, 221)',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              color: '#666666',
                              textAlign: 'left',
                              paddingRight: '15%',
                              fontFamily: 'Montserrat, sans-serif',
                              fontWeight: '600',
                              fontSize: '32px',
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            maxLength={10}
                            value={watch('amount')}
                            onChange={e => {
                              if (!isNaN(e.target.value)) {
                                setConditionAnnualReport(true);
                                trigger('amount');
                                setValue('amount', e.target.value);
                              }
                            }}
                            onBlur={() => {
                              if (parseInt(watch('amount')) >= 10000 && conditionAnnualReport) {
                                setModalAnnualReport({
                                  ...modalAnnualReport,
                                  show: true,
                                  onConfirm: () => {
                                    setValue('includeAnnualReport', true);
                                    setConditionAnnualReport(false);
                                  },
                                  onCancel: () => {
                                    setValue('includeAnnualReport', false);
                                    setConditionAnnualReport(false);
                                  },
                                });
                              } else {
                                setValue('includeAnnualReport', false);
                              }
                            }}
                          />
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              display: watch('frequency') === 1 ? 'none' : 'block',
                              position: 'absolute',
                              right: '16%',
                              color: '#666666',
                              width: 'auto',
                            }}>
                            /month
                          </span>
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                  <Form.Label className="text-danger" style={{marginLeft: 100}}>
                    {errors?.amount?.message}
                  </Form.Label>
                </Row>
                <div className="container-amount">
                  <Row>
                    {watch('frequency') === 1
                      ? onetimeAmount.map(amount => {
                          return (
                            <Col xl={3} xs={6} key={amount}>
                              <BoxAmount amount={amount} />
                            </Col>
                          );
                        })
                      : monthlyAmount.map(amount => {
                          return (
                            <Col xl={3} xs={6} key={amount}>
                              <BoxAmount amount={amount} />
                            </Col>
                          );
                        })}
                  </Row>
                </div>
                <div className="donation-info mt-0">
                  <div className="container-box-donation-type  mb-3 mt-0">
                    <BoxDonationType type="Individual" />
                    <BoxDonationType type="Organisation" />
                    {watch('frequency') === 1 ? <BoxDonationType type="Anonymous" /> : ''}
                  </div>
                  <h5 style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>Donor details</h5>
                  {watch('donationType') !== 'Anonymous' ? (
                    <span style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
                      {' '}
                      To receive a taxdeductible receipt, you are required to provide your{' '}
                      {watch('donationType') === 'Individual' ? 'NRIC/ FIN' : 'UEN'}
                    </span>
                  ) : (
                    <span style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
                      {' '}
                      A Donation receipt will be emailed to you if you have provided your email
                      address.
                    </span>
                  )}
                  {watch('donationType') !== 'Anonymous' ? (
                    <Col xl={12} xs={12}>
                      {' '}
                      <FormControlLabel
                        style={{
                          fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                        }}
                        control={
                          <GreenCheckbox checked={watch('isTax')} onChange={handleCheckbox} />
                        }
                        label="I require tax deduction receipt"
                      />
                    </Col>
                  ) : null}
                  <Row className="mt-3">
                    <Col xl={6} xs={12}>
                      <FloatingLabel label="Email">
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          style={{
                            boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                          }}
                          className="form-input mb-2"
                          {...register('email')}
                        />
                      </FloatingLabel>
                      {errors?.email && (
                        <Form.Label className="text-danger">{errors?.email?.message}</Form.Label>
                      )}
                    </Col>
                    {watch('donationType') !== 'Anonymous' && (
                      <Col xl={6} xs={12}>
                        <Row>
                          <Col lg={watch('salutation') === 'Others' ? 4 : 12}>
                            <FloatingLabel label="Salutation">
                              <Form.Select
                                {...register('salutation')}
                                style={{
                                  boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                }}>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Mdm">Mdm</option>
                                <option value="Dr">Dr</option>
                                <option value="Prof">Prof</option>
                                <option value="Others">Others</option>
                              </Form.Select>
                            </FloatingLabel>
                            {errors?.salutation && (
                              <Form.Label className="text-danger">
                                {errors?.salutation?.message}
                              </Form.Label>
                            )}
                          </Col>

                          {watch('salutation') === 'Others' && (
                            <Col lg={8}>
                              <FloatingLabel label="Other Salutation">
                                <Form.Control
                                  placeholder="Other Salutation"
                                  style={{
                                    boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                  }}
                                  className="form-input"
                                  {...register('otherSalutation')}
                                />
                              </FloatingLabel>
                              {errors?.otherSalutation && (
                                <Form.Label className="text-danger">
                                  {errors?.otherSalutation?.message}
                                </Form.Label>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {watch('donationType') === 'Individual' ? (
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Donor Name as per NRIC/FIN">
                          <Form.Control
                            placeholder="Donor Name as per NRIC/FIN"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            className="form-input"
                            {...register('fullname')}
                            onBlur={() => {
                              if (watch('donationType') === 'Individual') {
                                setValue('taxFullname', watch('fullname'));
                                trigger('taxFullname');
                              }
                            }}
                          />
                        </FloatingLabel>
                        {errors?.fullname && (
                          <Form.Label className="text-danger">
                            {errors?.fullname?.message}
                          </Form.Label>
                        )}
                      </Col>
                    ) : watch('donationType') === 'Organisation' ? (
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Organisation Name">
                          <Form.Control
                            type="text"
                            placeholder="Organisation Name"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            className="form-input mb-2"
                            {...register('orgName')}
                            onBlur={() => {
                              if (watch('donationType') === 'Organisation') {
                                setValue('taxFullname', watch('orgName'));
                              }
                            }}
                          />
                        </FloatingLabel>
                        {errors?.orgName && (
                          <Form.Label className="text-danger">
                            {errors?.orgName?.message}
                          </Form.Label>
                        )}
                      </Col>
                    ) : null}
                    {watch('donationType') !== 'Anonymous' && (
                      <Col xl={6} className="mb-2">
                        <FloatingLabel label="Preferred Name">
                          <Form.Control
                            placeholder="Preferred Name"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            className="form-input"
                            {...register('preferredName')}
                          />
                        </FloatingLabel>
                        {errors?.preferredName && (
                          <Form.Label className="text-danger">
                            {errors?.preferredName?.message}
                          </Form.Label>
                        )}
                      </Col>
                    )}
                  </Row>

                  {watch('donationType') !== 'Anonymous' && (
                    <Row className="mt-2">
                      {watch('isTax') && (
                        <Col xl={6} xs={12}>
                          <Row>
                            <Col md={5} xs={12}>
                              <FloatingLabel
                                label={`ID Type - ${
                                  watch('donationType') === 'Individual' ? 'NRIC/FIN' : 'UEN'
                                }`}>
                                <Form.Select {...register('idType')}>
                                  {watch('donationType') === 'Individual' ? (
                                    <>
                                      <option value="nric">NRIC/FIN</option>
                                    </>
                                  ) : watch('donationType') === 'Organisation' ? (
                                    <>
                                      <option value="uen">UEN</option>
                                    </>
                                  ) : null}
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                            <Col md={7} xs={12}>
                              <FloatingLabel
                                label={`${
                                  watch('donationType') === 'Individual'
                                    ? 'Tax Recipient ID Number'
                                    : 'Tax Recipient No'
                                }`}>
                                <Form.Control
                                  type="text"
                                  placeholder={`${
                                    watch('donationType') === 'Individual'
                                      ? 'Tax Recipient ID Number'
                                      : 'Tax Recipient No'
                                  }`}
                                  style={{
                                    boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                  }}
                                  className="form-input mb-2"
                                  {...register('idNumber')}
                                />
                              </FloatingLabel>
                              {errors?.idNumber && (
                                <Form.Label className="text-danger">
                                  {errors?.idNumber?.message}
                                </Form.Label>
                              )}
                            </Col>
                          </Row>{' '}
                        </Col>
                      )}

                      {watch('donationType') === 'Organisation' && (
                        <Col xl={6} xs={12}>
                          <FloatingLabel label="Contact Person Name">
                            <Form.Control
                              placeholder="Contact Person Name"
                              style={{
                                boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                              }}
                              className="form-input"
                              {...register('fullname')}
                            />
                          </FloatingLabel>
                          {errors?.fullname && (
                            <Form.Label className="text-danger">
                              {errors?.fullname?.message}
                            </Form.Label>
                          )}
                        </Col>
                      )}
                      {watch('isTax') && watch('donationType') == 'Anonymous' && (
                        <Row>
                          <Col xl={6} xs={12}>
                            <FloatingLabel label="Tax Recipient Full Name">
                              <Form.Control
                                type="text"
                                placeholder="Tax Recipient Full Name"
                                style={{
                                  boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                }}
                                className="form-input"
                                {...register('taxFullname')}
                                onBlur={() => {
                                  if (watch('taxFullname') === '') {
                                    return true;
                                  }
                                  setModalTax({
                                    ...modalTax,
                                    show: true,
                                    taxFullname: watch('taxFullname'),
                                    taxIdNumber: watch('idNumber'),
                                    onConfirm: () => {
                                      setModalTax({...modalTax, show: false});
                                      setTimeout(() => {
                                        setFocus('postalCode');
                                      }, 300);
                                    },
                                    onCancel: () => {
                                      setModalTax({...modalTax, show: false});
                                      setTimeout(() => {
                                        setFocus('fullname');
                                      }, 300);
                                    },
                                  });
                                }}
                              />
                            </FloatingLabel>
                            {errors?.taxFullname && (
                              <Form.Label className="text-danger">
                                {errors?.taxFullname?.message}
                              </Form.Label>
                            )}
                          </Col>
                          <Col md={12} sm={12} lg={6} xl={6} className="mb-2">
                            <FloatingLabel label="Phone Number">
                              <Form.Control
                                type="text"
                                className="form-input"
                                placeholder={'PHONE NUMBER'}
                                name="phone"
                                style={{
                                  boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                }}
                                {...register('phone')}
                              />
                            </FloatingLabel>
                            {errors?.phone && (
                              <Form.Label className="text-danger">
                                {errors?.phone?.message}
                              </Form.Label>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Row>
                  )}

                  {watch('isTax') && watch('donationType') !== 'Anonymous' && (
                    <Row>
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Tax Recipient Full Name">
                          <Form.Control
                            type="text"
                            placeholder="Tax Recipient Full Name"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            className="form-input"
                            {...register('taxFullname')}
                            onBlur={() => {
                              if (watch('taxFullname') === '') {
                                return true;
                              }
                              setModalTax({
                                ...modalTax,
                                show: true,
                                taxFullname: watch('taxFullname'),
                                taxIdNumber: watch('idNumber'),
                                onConfirm: () => {
                                  setModalTax({...modalTax, show: false});
                                  setTimeout(() => {
                                    setFocus('postalCode');
                                  }, 300);
                                },
                                onCancel: () => {
                                  setModalTax({...modalTax, show: false});
                                  setTimeout(() => {
                                    setFocus('fullname');
                                  }, 300);
                                },
                              });
                            }}
                          />
                        </FloatingLabel>
                        {errors?.taxFullname && (
                          <Form.Label className="text-danger">
                            {errors?.taxFullname?.message}
                          </Form.Label>
                        )}
                      </Col>
                      <Col md={12} sm={12} lg={6} xl={6} className="mb-2">
                        <FloatingLabel label="Phone Number">
                          <Form.Control
                            type="text"
                            className="form-input"
                            placeholder={'PHONE NUMBER'}
                            name="phone"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            {...register('phone')}
                          />
                        </FloatingLabel>
                        {errors?.phone && (
                          <Form.Label className="text-danger">{errors?.phone?.message}</Form.Label>
                        )}
                      </Col>
                    </Row>
                  )}

                  {watch('donationType') !== 'Anonymous' && (
                    <Row>
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Postal Code">
                          <Form.Control
                            type="text"
                            placeholder="Postal Code"
                            autoComplete="newPostalCode"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                            }}
                            className="form-input mb-2"
                            {...register('postalCode')}
                          />
                        </FloatingLabel>
                        {errors?.postalCode && (
                          <Form.Label className="text-danger">
                            {errors?.postalCode?.message}
                          </Form.Label>
                        )}
                      </Col>
                      <>
                        <Col xl={6} xs={12}>
                          <FloatingLabel label="Address">
                            <Form.Control
                              placeholder="Address"
                              as="textarea"
                              style={{
                                boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                                marginBottom: '10px',
                              }}
                              className="form-input"
                              {...register('address')}
                              onMouseOver={e => {
                                let count = e.target.value.split(/\r|\r\n|\n/).length;
                                e.target.style.height = count * 29 + 29 + 'px';
                              }}
                              onScroll={e => {
                                let count = e.target.value.split(/\r|\r\n|\n/).length;
                                e.target.style.height = count * 29 + 29 + 'px';
                              }}
                              onBlur={handleBlurAddress}
                            />
                          </FloatingLabel>
                          {errors?.address && (
                            <Form.Label className="text-danger">
                              {errors?.address?.message}
                            </Form.Label>
                          )}
                        </Col>
                        <Col xl={6} xs={12}>
                          <FloatingLabel label="Unit Number">
                            <Form.Control
                              type="text"
                              placeholder="Unit Number"
                              style={{
                                boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                              }}
                              className="form-input mb-2"
                              {...register('unitNumber')}
                            />
                          </FloatingLabel>
                          {errors?.unitNumber && (
                            <Form.Label className="text-danger">
                              {errors?.unitNumber?.message}
                            </Form.Label>
                          )}
                        </Col>
                        <Col xl={6} xs={12}>
                          <FloatingLabel label="Remarks">
                            <Form.Control
                              type="text"
                              placeholder="Remarks"
                              style={{
                                boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                              }}
                              className="form-input"
                              {...register('remarks')}
                            />
                          </FloatingLabel>
                          {errors?.remarks && (
                            <Form.Label className="text-danger">
                              {errors?.remarks?.message}
                            </Form.Label>
                          )}
                        </Col>
                      </>
                    </Row>
                  )}
                  {watch('paymentMethod') === 'Cheque' && (
                    <Row>
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Bank">
                          <Form.Select
                            style={{border: '2px solid #abc1f5'}}
                            {...register('bankName')}>
                            <option value="">Select Bank</option>
                            <option value="DBS">DBS</option>
                            <option value="UOB">UOB</option>
                            <option value="OCBC">OCBC</option>
                            <option value="HSBC">HSBC</option>
                            <option value="Standard Chartered">Standard Chartered</option>
                          </Form.Select>
                        </FloatingLabel>
                        {errors?.bankName && (
                          <Form.Label className="text-danger">
                            {errors?.bankName?.message}
                          </Form.Label>
                        )}
                      </Col>
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Cheque No/Mode">
                          <Form.Control
                            placeholder="Cheque No/Mode"
                            style={{
                              boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                              border: '2px solid #abc1f5',
                            }}
                            className="form-input"
                            {...register('chequeNo')}
                          />
                        </FloatingLabel>
                        {errors?.chequeNo && (
                          <Form.Label className="text-danger">
                            {errors?.chequeNo?.message}
                          </Form.Label>
                        )}
                      </Col>
                    </Row>
                  )}
                  {watch('donationType') !== 'Anonymous' ? (
                    <Row className="mt-1">
                      <Col xl={6} xs={12}>
                        <FloatingLabel label="Are You Lions Club Member">
                          <Form.Select {...register('isMember')}>
                            <option value={'no'} selected>
                              NO
                            </option>
                            <option value={'yes'}>YES</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      {watch('isMember') === 'yes' && (
                        <Col xl={6} xs={12}>
                          <FloatingLabel label="Lions Club">
                            <Form.Control
                              type="text"
                              placeholder="Lions Club"
                              autoComplete="lionsmember"
                              style={{
                                boxShadow: 'inset  0 2px 3px 0 rgb(0 0 0 / 10%)',
                              }}
                              className="form-input mb-2"
                              {...register('lionsMember')}
                              onChange={e => onChangeMember(e.target.value)}
                              onFocus={() => setShowOptionMember(true)}
                              onBlur={() => {
                                setTimeout(() => {
                                  setShowOptionMember(false);
                                }, 150);
                              }}
                            />
                            <Dropdown.Menu
                              show={showOptionMember}
                              container="body"
                              className="dropdown">
                              {suggestionMember.length > 0 &&
                                suggestionMember.map(item => {
                                  return (
                                    <Dropdown.Item
                                      key={item.Id}
                                      eventKey={item.Id}
                                      onClick={() => {
                                        setValue('lionsMember', item.Name);
                                        setValue('memberId', item.Id);
                                        trigger('lionsMember');
                                        setShowOptionMember(false);
                                      }}
                                      active={item.Id === watch('memberId')}>
                                      {item.Name}
                                    </Dropdown.Item>
                                  );
                                })}
                              {suggestionMember.length === 0 ? (
                                <Dropdown.Item disabled={true}>NO MATCH FOUND</Dropdown.Item>
                              ) : null}
                            </Dropdown.Menu>
                          </FloatingLabel>
                          {errors?.lionsMember && (
                            <Form.Label className="text-danger">
                              {errors?.lionsMember?.message}
                            </Form.Label>
                          )}
                        </Col>
                      )}
                    </Row>
                  ) : null}
                  {
                    <Row className="mt-3">
                      <Col xl={6} xs={12}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={watch('isPrivacy')}
                              onChange={event => {
                                setValue('isPrivacy', event.target.checked);
                                trigger('isPrivacy');
                              }}
                            />
                          }
                          label={
                            <>
                              I consent to LB Collection, disclosure and use of my personal data for
                              official purpose and in accordance with{' '}
                              <a
                                target="_blank"
                                href="https://www.lionsbefrienders.org.sg/privacy-policy/">
                                LB data protection policies
                              </a>
                            </>
                          }
                        />
                        {errors?.isPrivacy && (
                          <Form.Label className="text-danger">
                            {errors?.isPrivacy?.message}
                          </Form.Label>
                        )}
                      </Col>
                      <Col xl={6} xs={12}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={watch('isSubscibeUpdate')}
                              onChange={event => {
                                setValue('isSubscibeUpdate', event.target.checked);
                              }}
                            />
                          }
                          label="I give my consent to LB to update me on their programmes and upcoming events"
                        />
                      </Col>
                    </Row>
                  }
                  <Row className="mt-3">
                    <Col xl={3} lg={3} xs={12}>
                      <BoxPaymentMethod method={'Credit Card'} />
                    </Col>
                    {watch('frequency') === 1 && (
                      <Col xl={3} lg={3} xs={12}>
                        <BoxPaymentMethod method={'PayNow'} />
                      </Col>
                    )}
                    {watch('frequency') === 1 && watch('donationType') !== 'Anonymous' && (
                      <Col xl={3} lg={3} xs={12}>
                        <BoxPaymentMethod method={'Cheque'} />
                      </Col>
                    )}
                  </Row>
                </div>
                <Container style={{marginTop: '10px'}}>
                  <Row>
                    <Col xl={12} lg={12} xs={12} className="d-flex justify-content-center">
                      <Button
                        className="btn-submit-donate"
                        type="submit"
                        disabled={loadingDonation ? true : false}
                        style={{
                          fontWeight: 'bold',
                          height: '55px',
                          borderRadius: '10px',
                          fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                          background: '#2879d0',
                          border: '#2879d0',
                        }}>
                        {loadingDonation ? (
                          <svg
                            style={{
                              height: '36px',
                              width: '36px',
                              display: 'inline-block',
                            }}
                            version="1.1"
                            id="L9"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                            enableBackground="new 0 0 0 0"
                            xmlSpace="preserve">
                            <path
                              fill="#fff"
                              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                              <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite"
                              />
                            </path>
                          </svg>
                        ) : (
                          ''
                        )}
                        DONATE
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Card>
          </form>
          <ModalPaymentMethod
            show={modalPaymentMethod.show}
            type={modalPaymentMethod.type}
            onConfirm={modalPaymentMethod.onConfirm}
            onHide={() => setModalPaymentMethod({...modalPaymentMethod, show: false})}
            donationNumber={donationNumber}
            dataForm={dataForm}
          />
          <ModalError
            show={modalError.show}
            close={() => {
              setModalError({...modalError, show: false});
            }}
            error={modalError.error}
          />
          <ModalTax
            show={modalTax.show}
            taxFullname={modalTax.taxFullname}
            taxIdNumber={modalTax.taxIdNumber}
            onConfirm={modalTax.onConfirm}
            onCancel={modalTax.onCancel}
            onHide={() => setModalTax({...modalTax, show: false})}
          />
          <ModalAnnualReport
            show={modalAnnualReport.show}
            onConfirm={modalAnnualReport.onConfirm}
            onCancel={modalAnnualReport.onCancel}
            onHide={() => setModalAnnualReport({...modalAnnualReport, show: false})}
          />
        </Container>
      </section>
    </>
  );
}
