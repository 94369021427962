import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BannerImage from "./background.webp";
import "./index.css";

export function TitleImage({ title }) {
  return (
    <>
      {" "}
      <div
        className="container-title-image"
        style={{
          textAlign: "center",
        }}
      >
        {" "}
        <div className="bg-overlay">
          <LazyLoadImage src={BannerImage} className="bg-nav-overlay" />
        </div>
        <div className="message">
          <h1
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontFamily: "Montserrat,sans-serif",
            }}
          >
            {title}
          </h1>
        </div>
      </div>
    </>
  );
}
